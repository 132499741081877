import(/* webpackMode: "eager" */ "C:\\Users\\vivek\\Desktop\\GetExchangeId (NextJs)\\Sample\\Nextjs-tailwindcss-blog-template-main\\Nextjs-tailwindcss-blog-template-main\\node_modules\\next\\dist\\client\\image-component.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\vivek\\Desktop\\GetExchangeId (NextJs)\\Sample\\Nextjs-tailwindcss-blog-template-main\\Nextjs-tailwindcss-blog-template-main\\node_modules\\next\\dist\\client\\link.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\vivek\\Desktop\\GetExchangeId (NextJs)\\Sample\\Nextjs-tailwindcss-blog-template-main\\Nextjs-tailwindcss-blog-template-main\\public\\cricket-banner.jpg");
;
import(/* webpackMode: "eager" */ "C:\\Users\\vivek\\Desktop\\GetExchangeId (NextJs)\\Sample\\Nextjs-tailwindcss-blog-template-main\\Nextjs-tailwindcss-blog-template-main\\public\\gameremote.webp");
;
import(/* webpackMode: "eager" */ "C:\\Users\\vivek\\Desktop\\GetExchangeId (NextJs)\\Sample\\Nextjs-tailwindcss-blog-template-main\\Nextjs-tailwindcss-blog-template-main\\public\\GetID.webp");
;
import(/* webpackMode: "eager" */ "C:\\Users\\vivek\\Desktop\\GetExchangeId (NextJs)\\Sample\\Nextjs-tailwindcss-blog-template-main\\Nextjs-tailwindcss-blog-template-main\\public\\whatsapp.webp");
